import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig, useLogin, getToken } from "./authConfig";
import "./i18n";
import { getHeaders } from "../src/api/api";

import { VisibilityProvider, useDialogVisibility } from "./dialogVisibility";
import { LoadingProvider, useDialogLoading } from "./dialogLoading";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";

var layout;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <Layout />
        </MsalProvider>
    );
} else {
    layout = <Layout />;
}

initializeIcons();

async function projectLoader(params: {}) {
    console.log(params);
    let params_: { [key: string]: string } = params;
    const client = useLogin ? useMsal().instance : undefined;
    const token = client ? await getToken(client) : undefined;
    // Create a new URLSearchParams object
    let params__ = new URLSearchParams({
        projectId: params_["projectId"],
        customerId: params_["customerId"],
        question: params_["question"]
    });

    const apiUrl = `${BACKEND_URI}/test?${params__}`;

    const response = await fetch(apiUrl, {
        method: "GET",
        headers: getHeaders(token)
    });

    return await response.json();
    // return null;
}

const BACKEND_URI = "";
export async function endpointcall(id: string) {
    const client = useLogin ? useMsal().instance : undefined;
    const token = client ? await getToken(client) : undefined;
    // Create a new URLSearchParams object
    let params = new URLSearchParams({
        _id: id
    });

    const apiUrl = `${BACKEND_URI}/endpoint?${params}`;

    const response = await fetch(apiUrl, {
        method: "GET",
        headers: getHeaders(token)
    });

    return await response.json();
}

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/ask/Ask")
            },
            {
                path: "experimental",
                lazy: () => import("./pages/experimental/Experimental")
            },
            {
                path: "toolconfig",
                lazy: () => import("./pages/toolconfig/ToolConfig")
            },
            {
                path: "endpointconfig",
                lazy: () => import("./pages/endpointconfig/EndpointConfig")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
            // {
            //     path: "/project/:customerId/:projectId",
            //     loader: async ({ params }) => {
            //         return projectLoader(params);
            //     }
            // }
        ]
    }
]);

const ToggleDarkMode = () => {
    const { hidden, toggleHidden } = useDialogVisibility();
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleHidden!();
    };
    return (
        <>
            <h1>{hidden ? "🌙" : "🌞"}</h1>
            <button onClick={handleOnClick}>Toggle dark mode</button>
        </>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <VisibilityProvider>
        <LoadingProvider>
            {/* <ToggleDarkMode /> */}
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
        </LoadingProvider>
    </VisibilityProvider>
);
