import { useEffect, useState } from "react";
import { Stack, Checkbox } from "@fluentui/react";
import React from "react";

// inspired by: https://felixgerschau.com/react-typescript-context/

interface IDialogLoading {
    loading: boolean;
    setLoading?: (arg0: boolean) => void;
}

export const defaultState = {
    loading: true
};
const DialogLoading = React.createContext<IDialogLoading>(defaultState);

export const useDialogLoading = () => React.useContext(DialogLoading);

export const LoadingProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [loading, setLoading] = React.useState(defaultState.loading);

    // const toggleLoading = (flag: boolean) => {
    //     setLoading(flag);
    // };

    return (
        <DialogLoading.Provider
            value={{
                loading,
                setLoading
            }}
        >
            {children}
        </DialogLoading.Provider>
    );
};

export default DialogLoading;
