const BACKEND_URI = "";

import {
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    Config,
    IUploadResponse,
    IDeleteToolResponse,
    IUpdateToolResponse,
    IUpdateEndpointResponse,
    IDeleteToolFromEndpointResponse,
    IAddToolToEndpointResponse
} from "./models";
import { Item } from "../pages/toolconfig/ToolConfig";
import { useLogin, appServicesToken } from "../authConfig";
import { JointSchema } from "../pages/config/schemas/JointSchema";
import GenericSchema from "../pages/config/schemas/EndpointSchema";
import { z } from "zod";
import { XboxConsoleRegular } from "@fluentui/react-icons";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

// export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
//     const response = await fetch(`${BACKEND_URI}/ask`, {
//         method: "POST",
//         headers: getHeaders(idToken),
//         body: JSON.stringify(request)
//     });

//     const parsedResponse: ChatAppResponseOrError = await response.json();
//     if (response.status > 299 || !response.ok) {
//         throw Error(parsedResponse.error || "Unknown error");
//     }

//     return parsedResponse as ChatAppResponse;
// }

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as Config;
}

export async function testCall(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/test`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function intentApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/intent`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function experimentalApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/experimental`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function deleteToolApi(request: Item, idToken: string | undefined): Promise<IDeleteToolResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    console.log(request);
    const response = await fetch(`${BACKEND_URI}/deletetool`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Deleting tool failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IDeleteToolResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function deleteToolFromEndpoint(request: {}, idToken: string | undefined): Promise<IDeleteToolFromEndpointResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    console.log(request);
    const response = await fetch(`${BACKEND_URI}/removetoolfromendpoint`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Deleting tool from endpoint failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IDeleteToolFromEndpointResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function addToolToEndpoint(request: {}, idToken: string | undefined): Promise<IAddToolToEndpointResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    console.log(request);
    const response = await fetch(`${BACKEND_URI}/addtooltoendpoint`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Adding tool to endpoint failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IAddToolToEndpointResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function gettool(id: string, idToken: string | undefined): Promise<z.infer<typeof JointSchema>> {
    // Create a new URLSearchParams object
    const params = new URLSearchParams({
        _id: id
    });

    const apiUrl = `${BACKEND_URI}/gettool?${params}`;

    const response = await fetch(apiUrl, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return await response.json();
}

export async function getPrompts(id: string, idToken: string | undefined): Promise<z.infer<typeof GenericSchema>> {
    // Create a new URLSearchParams object
    const params = new URLSearchParams({
        _id: id
    });

    const apiUrl = `${BACKEND_URI}/prompts?${params}`;

    const response = await fetch(apiUrl, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return await response.json();
}

export async function updateToolApi(request: {}, idToken: string | undefined): Promise<IUpdateToolResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    console.log(request);
    const response = await fetch(`${BACKEND_URI}/updatetool`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Updating tool failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IUpdateToolResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function updateEndpoint(request: {}, idToken: string | undefined): Promise<IUpdateEndpointResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    const response = await fetch(`${BACKEND_URI}/endpoint/update`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Updating endpoint failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IUpdateEndpointResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function addToolApi(request: {}, idToken: string | undefined): Promise<IDeleteToolResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    console.log(request);
    const response = await fetch(`${BACKEND_URI}/addtool`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Adding tool failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IDeleteToolResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}

export async function uploadFileApi(request: FormData): Promise<IUploadResponse> {
    // Send a POST request to the "/upload" endpoint with the provided form data
    const response = await fetch(`${BACKEND_URI}/upload`, {
        method: "POST",
        body: request
    });

    // Check if the response status is not "OK".
    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    // Parse the JSON response into the IUploadResponse type.
    const dataResponse: IUploadResponse = await response.json();

    // Return the parsed data as the result of the Promise.
    return dataResponse;
}
