import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const parts = parsedAnswer
        .split("\\n")
        .join("<br />") // replace \\n with newline in markdown
        .split(/\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index == parts.length - 1) {
            if (part.substring(part.length - 1, part.length).includes('"')) {
                return part.substring(0, part.length - 1);
            } else {
                return part;
            }
        }
        if (index == 0) {
            if (part.substring(0, 2).includes('"')) {
                return part.substring(1, part.length);
            } else {
                return part;
            }
        }
        // f (part.substring(part.length - 1, part.length).includes('"'))
        if (part.includes("http")) {
            const urlRegex = /\(.*?\)/g;
            let url = part.match(urlRegex);
            if (url && url.length > 0) {
                let myurl = url[0].substring(1, url[0].length - 1);
                citations.push(myurl);

                let citationIndex = citations.length;

                return (
                    renderToStaticMarkup(
                        <a className="supContainer" title={myurl} onClick={() => onCitationClicked(myurl)}>
                            <sup>{citationIndex}</sup>
                        </a>
                    ) + part.replace(url[0], "")
                );
            } else return part;
        } else if (index % 2 === 0) {
            // remove intial and last quotation mark
            if (index == 0 && parts.length == 1 && part.substring(0, 1).includes('"')) return part.substring(1, part.length - 1);
            else if (index == 0 && parts.length > 1) return part.substring(1);
            else return part;
        } else if (part.includes("pdf")) {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part);

            return renderToStaticMarkup(
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        } else {
            // remove intial and last quotation mark
            if (index == 0 && parts.length == 1 && part.substring(0, 1).includes('"')) return part.substring(1, part.length - 1);
            else if (index == 0 && parts.length > 1) return part.substring(1);
            else return part;
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
