import { useEffect, useState } from "react";
import { Stack, Checkbox } from "@fluentui/react";
import React from "react";

// inspired by: https://felixgerschau.com/react-typescript-context/

interface IDialogVisibility {
    hidden: boolean;
    toggleHidden?: () => void;
}

export const defaultState = {
    hidden: true
};
const DialogVisibility = React.createContext<IDialogVisibility>(defaultState);

export const useDialogVisibility = () => React.useContext(DialogVisibility);

export const VisibilityProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [hidden, setHidden] = React.useState(defaultState.hidden);

    const toggleHidden = () => {
        setHidden(!hidden);
    };

    return (
        <DialogVisibility.Provider
            value={{
                hidden,
                toggleHidden
            }}
        >
            {children}
        </DialogVisibility.Provider>
    );
};

export default DialogVisibility;
